import { Injectable } from "@angular/core";
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { MaterialsFormComponent } from "src/app/materials-form/materials-form.components";
import Cookie from 'js-cookie';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    ngbModalOptions: ModalOptions = {
        backdrop: 'static',
        class: 'custom-modal'
      };
      
    constructor(
        private readonly modalService: BsModalService,
        public modalRef: BsModalRef
    ) { }

    openMaterialsForm(materialUrlPath: string) {
        if (Cookie.get('canDownloadMaterials') !== 'true') {
            this.ngbModalOptions.initialState = {
                materialUrlPath
            };
            this.modalRef = this.modalService.show(
                MaterialsFormComponent,
                this.ngbModalOptions
            );
        } else {
            window.open(materialUrlPath, '_blank');
        }
    }
}
